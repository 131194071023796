<template>
  <p class="h2">
    Playlists
    <router-link :to="{ name: 'PlaylistNew' }">
      <i class="bi bi-file-earmark-plus" />
    </router-link>
  </p>

  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">
          Title
        </th>
        <th scope="col">
          Owner
        </th>
        <th scope="col">
          #
        </th>
        <th scope="col" />
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in list" :key="item.id">
        <td>
          <router-link :to="{ name: 'Playlist', params: { id: item.id } }">
            {{ item.name }}
          </router-link>
        </td>
        <td>{{ item.owner }}</td>
        <td>{{ item.rezepte_num }}</td>
        <td>
          <router-link :to="{ name: 'PlaylistEdit', params: { id: item.id } }">
            <i class="bi bi-file-earmark-text" />
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "Playlists",
  components: {
  },
  props: {},
  data() {
    return {
      list: [
        //{ id: 1, name: "Global", owner: "Otto", rezepte_num: 15 },
        //{ id: 2, name: "Summertime", owner: "Thornton", rezepte_num: 9 },
        //{ id: 3, name: "Party", owner: "Ben", rezepte_num: 13 },
      ],
    };
  },

  mounted() {
    this.load();
  },
  methods: {
    load: async function () {
      try {
        const { data: list } = await axios.get(
          "/api/rest/dao/playlist?_populate=user"
        );
        this.list = list.map((row) => {
          row.owner = row.user.name;
          row.rezepte_num = (row.pizzas || []).length;
          row.id = row._id;
          return row;
        });
      } catch (err) {
        this.errors = err.response.data;
      }
    },
  },
};
</script>
